// custom typefaces
import "typeface-fira-code"
import "typeface-clear-sans"
import "typeface-open-sans"

import "./src/components/layout.css"

require("prismjs/themes/prism.css")
require("prismjs/plugins/line-numbers/prism-line-numbers.css")
require("prismjs/plugins/command-line/prism-command-line.css")
